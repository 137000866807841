.ExEd-toolbar .MuiButton-root {
    font-family: 'MusiSync';
    font-size: 48px;
    font-weight: normal;
    font-style: normal;
    text-transform: none;
}

.ExEd-toolbar .MuiButton-root div {
    margin-top: -14px;
    padding-bottom: 10px;
    line-height: 28px;
}

.ExEd-toolbar .MuiButton-root .MuiSvgIcon-root {
    font-size: 1rem;
}

#ExEd-paper {
    clear: both;
}